import Vue from "vue";
import VueRouter from "vue-router";

import Marcas from "../views/Marcas.vue";
import MarcasDetails from "../views/MarcasDetails.vue";
import Ocorrencias from "../views/Ocorrencias.vue";
import OcorrenciasDetails from "../views/OcorrenciasDetails.vue";
import Busca from "../views/Busca.vue";
import Metodologia from "../views/Metodologia.vue";
import Mais from "../views/Mais.vue";
import Sobre from "../views/Sobre.vue";
import TrabalhoEscravo from "../views/TrabalhoEscravo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "marcas",
    component: Marcas,
    meta: { title: "Marcas", menu: "marcas" }
  },
  {
    path: "*",
    redirect: "/",
    component: Marcas,
    meta: { title: "Marcas", menu: "marcas" }
  },
  {
    path: "/marcas/:post",
    name: "marcas_details",
    component: MarcasDetails,
    meta: { title: "Marcas - Detalhes", menu: "marcas" }
  },

  {
    path: "/ocorrencias",
    name: "ocorrencias",
    component: Ocorrencias,
    meta: { title: "Ocorrências", menu: "ocorrencias" }
  },
  {
    path: "/ocorrencias/:post",
    name: "ocorrencias_details",
    component: OcorrenciasDetails,
    meta: { title: "Ocorrências - Detalhes", menu: "ocorrencias" }
  },

  {
    path: "/busca",
    name: "busca",
    component: Busca,
    meta: { title: "Busca", menu: "busca" }
  },

  {
    path: "/metodologia",
    name: "metodologia",
    component: Metodologia,
    meta: { title: "Metodologia", menu: "metodologia" }
  },

  {
    path: "/mais",
    name: "mais",
    component: Mais,
    meta: { title: "Mais", menu: "mais" }
  },

  {
    path: "/sobre",
    name: "sobre",
    component: Sobre,
    meta: { title: "Repórter Brasil", menu: "mais" }
  },

  {
    path: "/trabalho-escravo",
    name: "trabalho_escravo",
    component: TrabalhoEscravo,
    meta: { title: "O que é Trabalho Escravo?", menu: "mais" }
  }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
