<template>
  <div class="metodologia container-lg px-lg-0 px-4" id="metodologia">
    <RenderHtml :html="post ? post.content.rendered : ''" />

    <Legenda class="mt-4" />
  </div>
</template>

<script>
export default {
  computed: {
    post() {
      const _pagina = this.$store.getters.paginas.find(
        _p => _p.slug == "metodologia"
      );

      return _pagina ? _pagina : null;
    }
  }
};
</script>

<style></style>
