<template>
  <div id="app" :class="['app', { has_loading: isLoading }]">
    <transition name="fade">
      <Loading v-if="isLoading" />
    </transition>

    <Header />

    <LinkApps />
    <Lgdp />

    <div class="app_views">
      <transition name="fade">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LinkAppsVue from "./modals/LinkApps.vue";
import LgdpAppsVue from "./modals/Lgdp.vue";

export default {
  beforeCreate() {
    this.$store.dispatch("updateConfiguracoes");

    this.$store.dispatch("updateQuestionarios").then(() => {
      this.$store.dispatch("updateMarcas");
    });

    this.$store.dispatch("updateOcorrencias");
    this.$store.dispatch("updatePaginas");
  },

  computed: {
    ...mapGetters(["marcas", "ocorrencias"]),

    isLoading() {
      return !(this.marcas.length && this.ocorrencias.length);
    }
  },

  components: {
    LinkApps: LinkAppsVue,
    Lgdp: LgdpAppsVue
  }
};
</script>

<style lang="scss">
@import "~@/assets/style/_custom_bootstrap.scss";
@import "~bootstrap/scss/bootstrap/";
@import "~@/assets/style/global";

.app {
  &_views {
    overflow: auto;
    padding-top: 42px;
    padding-bottom: 40px;
  }

  &.has_loading {
    overflow: hidden;
  }
}
</style>
