<template>
  <div class="ocorrencias_details container-lg px-lg-0 px-4">
    <h1 class="ocorrencias_details-titulo">
      {{ ocorrencia ? ocorrencia.titulo : "" }}
    </h1>

    <RenderHtml :html="ocorrencia ? ocorrencia.conteudo : ''" />
  </div>
</template>

<script>
export default {
  computed: {
    ocorrencia() {
      const _slug = this.$route.params.post;

      if (_slug) {
        const _ocorrencia = this.$store.getters.ocorrencias.find(
          _o => _o.slug == _slug
        );

        return _ocorrencia ? _ocorrencia : null;
      }

      return null;
    }
  }
};
</script>

<style lang="scss">
.ocorrencias_details {
  &-titulo {
    @include font-size(34px);
    color: $modalivre-azul-2;
    word-break: break-word;
    padding-top: 0;
    padding-bottom: 34px;
    font-weight: bold;
  }
}
</style>
