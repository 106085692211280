<template>
  <div class="marcas_details" id="marcas_details">
    <!-- imagem -->
    <figure class="marcas_details-imagem container-lg px-lg-0 px-4">
      <img :src="imagem" />
    </figure>

    <!-- Conduta -->
    <div class="marcas_details_conduta container-lg px-lg-0 px-4 mb-4">
      <StatusIcon
        class="marcas_details_conduta-status"
        :formulario="formulario"
      />
      <h2 class="destaque">CONDUTA</h2>
      <span class="d-block pontos">{{ pontosGerais }}</span>
    </div>

    <!-- Secoes -->
    <div class="marcas_details_secoes">
      <SecaoPicker v-model="actvieSecaoId" :geral="false" />

      <div class="container-lg px-lg-0 px-4">
        <div class="pontos mb-2">
          {{ activeSecaoTextoPontos }}
          <button
            type="button"
            class="btn btn-link"
            @click="
              () => {
                this.$refs.modalClassificacao.open();
              }
            "
          >
            (entenda a avaliação)
          </button>
          <Classificacao ref="modalClassificacao" />
        </div>

        <span class="marcas_details_secoes-descricao texto">{{
          activeSecaoDescricao
        }}</span>

        <a
          v-if="activeSecaoLink"
          href="#"
          @click="onClickOcorrencia"
          class="btn btn-externo"
          >Saiba mais sobre a ocorrência</a
        >
      </div>
    </div>

    <!-- Sobre a marca -->
    <div class="marcas_details_sobre container-lg px-lg-0 px-4 mt-4">
      <h2 class="destaque">Sobre a marca</h2>

      <RenderHtml :html="sobre" />

      <a :href="linkQuestionarioMarca" class="btn btn-externo"
        >Veja o questionário de avaliação</a
      >
    </div>

    <!-- Converse -->
    <div class="marcas_details_converse container-lg px-lg-0 px-4 mt-4">
      <h2 class="destaque mb-3">Converse com a marca</h2>

      <div class="d-flex">
        <a
          v-if="urlTwitter"
          :href="urlTwitter"
          class="btn btn-twitter mr-3"
          title="Twitter"
          ><i class="ic_modalivre_webapp__twitter"></i
        ></a>

        <a
          v-if="urlFacebook"
          :href="urlFacebook"
          class="btn btn-facebook mr-3"
          title="Facebook"
          ><i class="ic_modalivre_webapp__facebook"></i
        ></a>

        <a
          v-if="urlInstagram"
          :href="urlInstagram"
          class="btn btn-instagram mr-3"
          title="Instagram"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../Api";
import Classificacao from "../modals/Classificacao";

export default {
  components: { Classificacao: Classificacao },

  data: () => ({
    actvieSecaoId: 0
  }),

  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);

    next();
  },

  computed: {
    marca() {
      const _slug = this.$route.params.post;

      if (_slug) {
        const _marca = this.$store.getters.marcas.find(
          _m => _m.post.slug == _slug
        );

        return _marca ? _marca : null;
      }

      return null;
    },

    formulario() {
      return this.marca ? this.marca.questionario.formulario : {};
    },

    titulo() {
      return this.marca ? this.marca.post.titulo : "";
    },

    imagem() {
      return this.marca ? this.marca.post.imagem : "";
    },

    pontosGerais() {
      return this.marca
        ? `${this.formulario.pontos_alcancados} pontos gerais`
        : "";
    },

    sobre() {
      return this.marca ? this.marca.post.sobre : "";
    },

    linkQuestionarioMarca() {
      return this.marca ? `${Api.urlSite}/marcas/${this.marca.post.slug}` : "";
    },

    urlTwitter() {
      return this.marca ? this.marca.post.urlTwitter : "";
    },

    urlFacebook() {
      return this.marca ? this.marca.post.urlFacebook : "";
    },

    urlInstagram() {
      return this.marca ? this.marca.post.urlInstagram : "";
    },

    activeSecao() {
      if (!this.actvieSecaoId || !this.marca) {
        return null;
      }

      const _secao = this.marca.questionario.secoes.find(
        _s => _s.id == this.actvieSecaoId
      );

      return _secao ? _secao : null;
    },

    activeSecaoTextoPontos() {
      return this.activeSecao
        ? `${this.activeSecao.pontos} pontos em ${this.activeSecao.titulo}`
        : "";
    },

    activeSecaoDescricao() {
      return this.activeSecao ? this.activeSecao.destaque_descricao : "";
    },

    activeSecaoLink() {
      return this.activeSecao ? this.activeSecao.destaque_link : "";
    }
  },

  methods: {
    onClickOcorrencia() {
      const _link = String(this.activeSecaoLink).slice(
        String(this.activeSecaoLink).indexOf("//")
      );
      const _pathOcorrencias = `${Api.urlSite.slice(
        Api.urlSite.indexOf("//")
      )}/ocorrencias/`;

      if (_link.includes(_pathOcorrencias)) {
        // const _ocorrencias = store.getState().ocorrencias.posts;
        // const _slug = _link.replace(_pathOcorrencias, '').replace('/', '');
        // const _ocorrencia = _ocorrencias.find(_o => _o.slug == _slug);
        // this.props.navigation.navigate('OcorrenciasDetalhes', { tituloPagina: _ocorrencia.titulo, post: _ocorrencia });
      } else {
        window.location = this.activeSecaoLink;
      }
    }
  }
};
</script>

<style lang="scss">
.marcas_details {
  &-imagem {
    position: relative;
    margin-top: -42px;
    text-align: center;

    img {
      width: auto;
      height: auto;
      object-fit: cover;
      max-height: 300px;
      max-width: 100%;
    }
  }

  .marcas_details_conduta-status {
    float: left;
    margin: 0 11px 30px 0px;
  }

  .btn-link {
    font-weight: 600;
    text-decoration: unset !important;
  }
}
</style>
