<template>
  <div class="ranking_header">
    <button
      type="button"
      @click="
        () => {
          this.change('az');
        }
      "
      :class="[modoAtivo == 'az' ? 'active' : '', 'btn']"
    >
      A a Z
    </button>

    <button
      type="button"
      @click="
        () => {
          this.change('ranking');
        }
      "
      :class="[modoAtivo == 'ranking' ? 'active' : '', 'btn']"
    >
      Ranking
    </button>
  </div>
</template>

<script>
export default {
  props: ["value"],

  computed: {
    modoAtivo() {
      return this.value;
    }
  },

  methods: {
    change(modo) {
      this.$emit("input", modo);
    }
  }
};
</script>

<style lang="scss">
.ranking_header {
  display: flex;
  margin-bottom: 20px;

  .btn {
    // outline: none !important;
    flex: 1;
    @include font-size(26px);
    font-weight: 600;
    border-radius: 0;
    margin: 0 -1px;
    border: none;
    border-bottom: 4px solid $gray-500;
    box-shadow: unset !important;

    &.active {
      border-color: $modalivre-azul;
      color: $modalivre-azul;
    }
  }
}
</style>
