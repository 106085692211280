<template>
  <p class="view_description container-lg px-lg-0 px-4">{{ texto }}</p>
</template>

<script>
export default {
  props: ["texto"]
};
</script>

<style lang="scss">
.view_description {
  @include font-size(22px);
  text-align: center;
  margin-top: 12px;
  margin-bottom: 35px;
  font-weight: 600;
}
</style>
