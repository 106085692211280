import api from "../../Api";

// initial state
// shape: [{ id, quantity }]
const state = {
  posts: [],
  modelos: []
};

// getters
const getters = {
  questionarios: state =>
    state.posts.map(_q => ({
      ..._q,
      marcas: _q.marcas.map(__m => __m),
      formulario: JSON.parse(_q.formulario),
      secoes: JSON.parse(_q.secoes),
      questoes: JSON.parse(_q.questoes)
    })),
  modelo: state => (state.modelos.length ? state.modelos[0] : {})
};

// actions
const actions = {
  async updateQuestionarios(context) {
    // Carregar modelos
    await context.dispatch("carregarModelos");

    const _questionarios = (await api.wpRequestPosts("questionario")).map(
      _q => ({
        // const _questionarios = (
        //   await (
        //     await api.get(`${api.urlApiWp}/questionario?status=publish&_embed`)
        //   ).json()
        // ).map(_q => ({
        id: _q.id,
        titulo: _q.title.rendered,
        marcas: Array.isArray(_q.acf.marcas) ? _q.acf.marcas : [],
        formulario: _q.custom_fields._mb_mlp_formulario[0],
        secoes: _q.custom_fields._mb_mlp_secoes[0],
        questoes: _q.custom_fields._mb_mlp_questoes[0]
      })
    );

    context.commit("setQuestionarios", _questionarios);
    return;
  },

  /**
   * Retorna os questionarios modelos
   * @return {Promise<QuestionariosSchema>}
   */
  async carregarModelos(context) {
    const _r = await api.get(`${api.urlApiWp}/pesquisa?status=any`);

    const _modelos = !_r.ok
      ? []
      : (await _r.json()).map(_q => ({
          post: _q,
          formulario: JSON.parse(_q.custom_fields._mb_mlp_formulario[0]),
          secoes: JSON.parse(_q.custom_fields._mb_mlp_secoes[0]),
          questoes: JSON.parse(_q.custom_fields._mb_mlp_questoes[0])
        }));

    context.commit("setModelos", _modelos);
  }
};

// mutations
const mutations = {
  setQuestionarios(state, posts) {
    state.posts = posts;
  },

  setModelos(state, modelos) {
    state.modelos = modelos;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
