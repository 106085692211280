<template>
  <div class="trabalho_escravo container-lg px-lg-0 px-4" id="trabalho_escravo">
    <RenderHtml :html="post ? post.content.rendered : ''" />

    <BtnPostPaginas :post="post ? post : {}" class="mt-5" />
  </div>
</template>

<script>
export default {
  computed: {
    post() {
      const _pagina = this.$store.getters.paginas.find(
        _p => _p.slug == "o-que-e-trabalho-escravo"
      );

      return _pagina ? _pagina : null;
    }
  }
};
</script>

<style></style>
