import api from "../../Api";

// initial state
const state = {
  posts: []
};

// getters
const getters = {
  paginas: state => state.posts
};

// actions
const actions = {
  async updatePaginas(context) {
    context.commit("setPaginas", await api.wpRequestPosts("paginas_app"));
  }
};

// mutations
const mutations = {
  setPaginas(state, posts) {
    state.posts = posts;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
