/**
 * padLeft
 * @param {*} n
 * @param {*} width
 * @param {*} z
 */
export const padLeft = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};
