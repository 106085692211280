import api from "../../Api";
// import store from "..";getters

const Entities = require("html-entities").AllHtmlEntities;
const htmlEntities = new Entities();

// initial state
// shape: [{ id, quantity }]
const state = {
  posts: []
};

// getters
const getters = {
  marcas: (state, getters) =>
    state.posts
      .map(_m => {
        const __q = getters.questionarios.find(_q => _q.marcas.includes(_m.id));
        return {
          post: { ..._m },
          questionario: __q ? __q : {}
        };
      })
      .filter(_m => Object.keys(_m.questionario).length)
};

// actions
const actions = {
  async updateMarcas(context) {
    const _marcas = (await api.wpRequestPosts("marcas")).map(_m => {
      // const _marcas = (
      //   await (
      //     await api.get(`${api.urlApiWp}/marcas?status=publish&_embed`)
      //   ).json()
      // ).map(_m => {
      let __urlImagem = "";

      if (
        _m.hasOwnProperty("_embedded") &&
        _m._embedded.hasOwnProperty("wp:featuredmedia")
      ) {
        __urlImagem = _m._embedded["wp:featuredmedia"][0].source_url;
      }

      return {
        id: _m.id,
        slug: _m.slug,
        titulo: htmlEntities.decode(_m.title.rendered),
        sobre: htmlEntities.decode(_m.excerpt.rendered),
        urlFacebook: _m.acf.facebook ? _m.acf.facebook : "",
        urlTwitter: _m.acf.twitter ? _m.acf.twitter : "",
        urlInstagram: _m.acf.instagram ? _m.acf.instagram : "",
        imagem: __urlImagem
      };
    });

    context.commit("setMarcas", _marcas);
  }
};

// mutations
const mutations = {
  setMarcas(state, posts) {
    state.posts = posts;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
