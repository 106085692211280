<template>
  <nav class="header_nav container-lg">
    <ul class="nav justify-content-sm-center">
      <li class="nav-item">
        <router-link
          to="/"
          :class="['nav-link', { active: classActive('marcas') }]"
        >
          <i class="ic_modalivre_webapp__marcas"></i>Marcas
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          to="/ocorrencias"
          :class="['nav-link', { active: classActive('ocorrencias') }]"
        >
          <i class="ic_modalivre_webapp__ocorrencias"></i>Ocorrências
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          to="/busca"
          :class="['nav-link', { active: classActive('busca') }]"
        >
          <i class="ic_modalivre_webapp__busca"></i>Busca
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          to="/metodologia"
          :class="['nav-link', { active: classActive('metodologia') }]"
        >
          <i class="ic_modalivre_webapp__metodologia"></i>Metodologia
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          to="/mais"
          :class="['nav-link', { active: classActive('mais') }]"
        >
          <i class="ic_modalivre_webapp__mais"></i>Mais
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    classActive(menu) {
      const _menuRoute = this.$route.meta.menu;

      return _menuRoute ? _menuRoute == menu : false;
    }
  }
};
</script>

<style lang="scss">
.header_nav {
  padding: 0;
  overflow: auto;
  margin-bottom: -4px;

  .nav {
    flex-wrap: nowrap;

    &-item {
      flex: 1;
    }

    // Icones
    &-link {
      text-align: center;
      border-bottom: 4px solid transparent;
      z-index: 1;
      position: relative;
      color: $black;
      @include font-size(12px);
      font-weight: 500;
      padding: 0 7px 9px 7px;

      [class*="ic_modalivre_webapp__"] {
        @include font-size(20px);
        display: block;
        margin-bottom: 11px;
      }

      &.active {
        color: $modalivre_azul;
        border-bottom: 4px solid $modalivre_azul;
      }
    }
  }
}
</style>
