<template>
  <ul class="legenda">
    <li
      class="legenda_item"
      v-for="(item, index) in classificacao"
      :key="index"
    >
      <StatusIcon
        :formulario="{
          pontos_alcancados: item.pontuacao.minimo,
          pontos_max: item.pontuacao.maximo,
          status: item.pontuacao.sem_pontuacao ? '' : 'finalizar'
        }"
      />
      <span class="legenda_item-titulo">{{ item.titulo }}</span>
      <span class="legenda_item-pontos pontos">{{
        textoPontuacao(item.pontuacao)
      }}</span>
      <span class="legenda_item-descricao">{{ item.descricao }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    classificacao() {
      const _configuracoes = this.$store.state.Configuracoes.configuracoes;

      return !_configuracoes.classificacao ||
        !_configuracoes.classificacao.length
        ? []
        : _configuracoes.classificacao;
    }
  },

  methods: {
    textoPontuacao({ sem_pontuacao, minimo, maximo }) {
      return sem_pontuacao
        ? "Sem pontuação"
        : `Pontuação entre ${String(minimo).replace(".", ",")}% e ${String(
            maximo
          ).replace(".", ",")}%`;
    }
  }
};
</script>

<style lang="scss">
.legenda {
  list-style: none;
  padding: 0;

  &_item {
    display: flex;
    flex-direction: column;
    padding: 22px 29px 22px 67px;
    background-color: $white;
    border-radius: 8px;
    position: relative;
    box-shadow: 0px 6px 16px rgba($black, 0.1);

    &:nth-child(n + 2) {
      margin-top: 32px;
    }

    &:before {
      content: "";
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 35px;
      background-color: $gray-400;
    }

    .status_icon {
      position: absolute;
      top: 24px;
      left: 22px;
    }

    &-titulo {
      @include font-size(20px);
      font-weight: 600;
      margin-bottom: 4px;
    }

    &-pontos {
      margin-bottom: 10px;
    }

    &-descricao {
      @include font-size(18px);
    }
  }
}
</style>
