import { WP_API } from "./constants";

/**
 * Classe com informações de acesso da Rest API.
 */
const Api = {
  /**
   * Url do site.
   * @param string urlSite
   */
  urlSite: `${WP_API}`,

  /**
   * Url para acesso ao Rest API do Wordpress.
   * @param string urlApi
   */
  urlApi: `${WP_API}/wp-json`,

  /**
   * Url para consumo da Rest API pública do Wordpress.
   * @param string urlApi
   */
  urlApiWp: `${WP_API}/wp-json/wp/v2`,

  /**
   * Url para consumo da Rest API pública dos campos ACF.
   * @param string urlApi
   */
  urlApiAcf: `${WP_API}/wp-json/acf/v3`,

  /**
   * Token gerado para acesso.
   */
  token: "",

  /**
   * Gerar token para o Wordpress
   */
  async gerarToken() {
    if (!(await this.validarToken())) {
      let _token = await fetch(`${this.urlApi}/jwt-auth/v1/token`, {
        method: "POST",
        body: JSON.stringify({
          username: "consumidorapirest",
          password: "XXmobidick3045**"
        }),
        headers: { "Content-Type": "application/json" }
      }).then(resposta => resposta.json());

      if ("token" in _token) {
        this.token = _token.token;
      } else {
        this.token = "";
      }
    }
  },

  /**
   * Realiza a validação do Token
   */
  async validarToken() {
    if (!this.token) {
      return false;
    }

    const _resposta = await fetch(`${this.urlApi}/jwt-auth/v1/token/validate`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }).then(_r => _r.json());

    return "code" in _resposta && _resposta.code === "jwt_auth_valid_token";
  },

  /**
   * Realiza um GET
   * @param {string} url
   * @param {object} config
   */
  async get(url, config = {}) {
    await this.gerarToken();

    let headers = { Authorization: `Bearer ${this.token}` };

    if (config.hasOwnProperty("headers")) {
      headers = { ...config.headers, ...headers };
    }

    return fetch(url, {
      ...config,
      method: "GET",
      headers
    });
  },

  /**
   * Realiza um POST
   * @param {string} url
   * @param {object} config
   */
  async post(url, config = {}) {
    await this.gerarToken();

    let headers = { Authorization: `Bearer ${this.token}` };

    if (config.hasOwnProperty("headers")) {
      headers = { ...config.headers, ...headers };
    }

    return fetch(url, {
      ...config,
      method: "POST",
      headers
    });
  },

  /**
   * Retorna todos os posts de um post type
   * @param {string} postType
   * @param {number} size
   * @param {number} count
   * @param {Array} dados
   */
  async wpRequestPosts(postType, size = 100, count = 0, dados = []) {
    const _request = async (postType, size = 100, count = 0, dados = []) => {
      const _r = await Api.get(
        `${
          this.urlApiWp
        }/${postType}?status=publish&_embed&per_page=${size}&offset=${size *
          count}`
      );

      if (_r.ok) {
        const _rSize = parseInt(_r.headers.get("x-wp-total"));

        dados = [...dados, ...(await _r.json())];

        if (dados.length < _rSize) {
          return _request(postType, size, count + 1, dados);
        }

        return dados;
      }

      return [];
    };

    return await _request(postType, size, count, dados);
  }
};

export default Api;
