<template>
  <ul class="secao_picker justify-content-lg-center">
    <li>
      <button
        type="button"
        v-if="geral"
        @click="() => change(0)"
        :class="['btn', !activeSecao ? 'selected' : '']"
      >
        Geral
      </button>
    </li>

    <li v-for="secao in secoes" :key="secao.id">
      <button
        type="button"
        @click="() => change(secao.id)"
        :class="['btn', activeSecao == secao.id ? 'selected' : '']"
      >
        {{ secao.titulo }}
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: Number,
    geral: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters(["modelo"]),

    secoes() {
      const _secoes = (!this.modelo.secoes ? [] : this.modelo.secoes).filter(
        _s => _s.visivel_app
      );

      if (!this.geral && _secoes.length) {
        this.change(_secoes[0].id);
      }

      return _secoes;
    },

    activeSecao() {
      return this.value;
    }
  },

  methods: {
    change(newValue) {
      this.$emit("input", newValue);
    }
  }
};
</script>

<style lang="scss">
.secao_picker {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding-top: 22px;
  padding-bottom: 26px;

  .btn {
    border-radius: 22px;
    box-shadow: 0px 5px 20px rgba($black, 0.3) !important;
    color: $modalivre-azul;
    margin: 0 8px;
    background-color: $white;
    padding: 8.5px 24px;
    font-weight: 500;
    white-space: nowrap;

    &.selected {
      color: $white;
      background-color: $modalivre-azul;
    }
  }
}
</style>
