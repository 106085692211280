<template>
  <div class="mais container-lg px-lg-0 px-4" id="mais">
    <RenderHtml :html="post ? post.content.rendered : ''" class="mb-5" />

    <router-link to="/trabalho-escravo" class="btn mais_btn">
      <span class="mais_btn-titulo">O que é trabalho escravo</span>
      <i class="ic_modalivre_webapp__arrow mais_btn-ic_arrow"></i>
    </router-link>

    <router-link to="/sobre" class="btn mais_btn">
      <span class="mais_btn-titulo">Sobre a Repórter Brasil</span>
      <i class="ic_modalivre_webapp__arrow mais_btn-ic_arrow"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    post() {
      const _pagina = this.$store.getters.paginas.find(_p => _p.slug == "mais");

      return _pagina ? _pagina : null;
    }
  }
};
</script>

<style lang="scss">
.mais {
  &_btn {
    display: flex;
    height: 100px;
    align-items: center;
    color: $modalivre-azul !important;
    background-color: $white;
    position: relative;
    justify-items: center;
    border-radius: 8px;
    padding: 19px 40px;
    box-shadow: 0px 6px 16px rgba($black, 0.0979021);
    margin: 9.5px 0;

    &-titulo {
      max-width: calc(100% - (40px + 15px));
      font-weight: 600;
      @include font-size(20px);
    }

    &-ic_arrow {
      @include font-size(35px);
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      color: $black !important;
    }
  }
}
</style>
