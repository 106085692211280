import api from "../../Api";

const Entities = require("html-entities").AllHtmlEntities;
const htmlEntities = new Entities();

// initial state
const state = {
  posts: []
};

// getters
const getters = {
  ocorrencias: state => state.posts
};

// actions
const actions = {
  async updateOcorrencias(context) {
    const _ocorrencias = (await api.wpRequestPosts("ocorrencias")).map(_o => ({
      id: _o.id,
      slug: _o.slug,
      titulo: htmlEntities.decode(_o.title.rendered),
      conteudo: htmlEntities.decode(_o.content.rendered),
      resumo: htmlEntities.decode(_o.acf.resumo ? _o.acf.resumo : ""),
      data: new Date(_o.date)
    }));

    context.commit("setOcorrencias", _ocorrencias);
  }
};

// mutations
const mutations = {
  setOcorrencias(state, posts) {
    state.posts = posts;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
