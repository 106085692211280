import api from "../../Api";

// initial state
const camposConfiguracoes = {
  textoConclusao: {},
  classificacao: [],
  classificacaoPontuacaoMenorMinimo: 0,
  avisoSair: {},
  modals: {},
  notificacoesEmail: {}
};

const state = {
  configuracoes: Object.assign({}, camposConfiguracoes)
};

// getters
const getters = {};

// actions
const actions = {
  async updateConfiguracoes(context) {
    let _configuracoes = {};

    try {
      const _r = await api.get(`${api.urlApiAcf}/options/pesquisa_options`);

      if (_r.ok) {
        const _c = await _r.json();

        _configuracoes = {
          textoConclusao: _c.acf.texto_de_conclusao_do_questionario,
          classificacao: _c.acf.classificacao,
          avisoSair: _c.acf.aviso_sair,
          modals: _c.acf.modals,
          notificacoesEmail: _c.acf.notificacoes_por_email,
          classificacaoPontuacaoMenorMinimo: Array.isArray(_c.acf.classificacao)
            ? _c.acf.classificacao.reduce((__menorMinimo, __c) => {
                const ___pontuacaoMinimo = parseFloat(__c.pontuacao.minimo);

                return ___pontuacaoMinimo <= __menorMinimo &&
                  !__c.pontuacao.sem_pontuacao
                  ? ___pontuacaoMinimo
                  : __menorMinimo;
              }, 100)
            : 0
        };
      }
    } catch (error) {
      _configuracoes = {};
    }

    context.commit("setConfiguracoes", _configuracoes);
  }
};

// mutations
const mutations = {
  setConfiguracoes(state, configuracoes) {
    state.configuracoes = Object.assign(
      { ...camposConfiguracoes },
      configuracoes
    );
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
