<template>
  <div class="marcas" id="marcas">
    <ViewDescription
      texto="Confira como as marcas combatem o trabalho escravo"
    />

    <Ranking />
  </div>
</template>

<script>
export default {};
</script>
