import Vue from "vue";
import Vuex from "vuex";

import Configuracoes from "./modules/Configuracoes";
import Marcas from "./modules/Marcas";
import Questionarios from "./modules/Questionarios";
import Ocorrencias from "./modules/Ocorrencias";
import Paginas from "./modules/Paginas";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Configuracoes,
    Marcas,
    Questionarios,
    Ocorrencias,
    Paginas
  },
  strict: debug
});
