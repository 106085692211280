<template>
  <div class="header" id="header">
    <div class="header_navbar container-lg px-lg-0 px-sm-4 px-2 navbar">
      <a
        class="header_btn_nav navbar-brand"
        href="#"
        @click.prevent="onClickBtnNav"
      >
        <transition name="fade" mode="out-in">
          <svg
            v-if="isHome"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="190"
            height="89"
            viewBox="0 0 190 89"
          >
            <defs>
              <clipPath id="a">
                <path
                  d="M0,0H40.683V45.93H0Z"
                  transform="translate(0.283 0.07)"
                  fill="none"
                ></path>
              </clipPath>
              <clipPath id="b">
                <path
                  d="M0,0H15.893V15.893H0Z"
                  transform="translate(0 0.084)"
                  fill="none"
                ></path>
              </clipPath>
            </defs>
            <path
              d="M34,9.816V21.838a2.183,2.183,0,0,1-.57,1.554,1.946,1.946,0,0,1-1.49.608,1.979,1.979,0,0,1-1.511-.63,2.155,2.155,0,0,1-.591-1.531V9.861q0-5.9-4.82-5.9a6.121,6.121,0,0,0-3.089.788A5.621,5.621,0,0,0,19.782,6.89a5.91,5.91,0,0,0-.767,2.972V21.838a2.155,2.155,0,0,1-.591,1.531,1.98,1.98,0,0,1-1.511.63,1.946,1.946,0,0,1-1.49-.608,2.18,2.18,0,0,1-.57-1.554V9.816a7.407,7.407,0,0,0-1.1-4.255,4.091,4.091,0,0,0-3.637-1.6A5.783,5.783,0,0,0,5.849,5.651,5.661,5.661,0,0,0,4.163,9.816V21.838a2.155,2.155,0,0,1-.592,1.531A1.98,1.98,0,0,1,2.059,24a1.946,1.946,0,0,1-1.49-.608A2.18,2.18,0,0,1,0,21.838V2.567A2.158,2.158,0,0,1,.591,1.036,1.931,1.931,0,0,1,2.059.405a1.982,1.982,0,0,1,1.511.631,2.156,2.156,0,0,1,.592,1.531V4.143A9.694,9.694,0,0,1,7.207,1.193,7.661,7.661,0,0,1,11.348,0Q16.78,0,18.4,4.953a9.451,9.451,0,0,1,3.2-3.422A8.237,8.237,0,0,1,26.332,0Q34,0,34,9.816"
              transform="translate(64 9)"
              fill="#456975"
            ></path>
            <path
              d="M12,24a12.259,12.259,0,0,1-6.068-1.539A11.407,11.407,0,0,1,1.591,18.2,12.026,12.026,0,0,1,0,12.044a12.265,12.265,0,0,1,1.591-6.2A11.324,11.324,0,0,1,5.932,1.538,12.276,12.276,0,0,1,12,0a12.283,12.283,0,0,1,6.069,1.538,11.332,11.332,0,0,1,4.341,4.308A12.274,12.274,0,0,1,24,12.044,12.034,12.034,0,0,1,22.409,18.2a11.415,11.415,0,0,1-4.341,4.263A12.265,12.265,0,0,1,12,24ZM12,3.868A7.419,7.419,0,0,0,5.342,7.8a8.724,8.724,0,0,0-1.023,4.242A8.5,8.5,0,0,0,5.342,16.22,7.454,7.454,0,0,0,12,20.132a7.454,7.454,0,0,0,6.659-3.912,8.5,8.5,0,0,0,1.023-4.176A8.724,8.724,0,0,0,18.659,7.8,7.419,7.419,0,0,0,12,3.868Z"
              transform="translate(105 9)"
              fill="#456975"
            ></path>
            <path
              d="M10.543,33a9.738,9.738,0,0,1-5.358-1.54,10.8,10.8,0,0,1-3.791-4.29A13.535,13.535,0,0,1,0,20.988a13.711,13.711,0,0,1,1.372-6.182,10.652,10.652,0,0,1,3.768-4.29,9.6,9.6,0,0,1,5.271-1.54,9.576,9.576,0,0,1,4.27.968,9.207,9.207,0,0,1,3.18,2.508V2.111A2.12,2.12,0,0,1,18.427.594,1.962,1.962,0,0,1,19.91,0a2.03,2.03,0,0,1,1.5.594A2.074,2.074,0,0,1,22,2.111V30.448a2.093,2.093,0,0,1-.588,1.5,1.991,1.991,0,0,1-1.5.616,1.962,1.962,0,0,1-1.482-.594,2.122,2.122,0,0,1-.566-1.518V29.216A8.888,8.888,0,0,1,14.791,31.9,8.667,8.667,0,0,1,10.543,33Zm.48-20.153A6.451,6.451,0,0,0,7.385,13.9,7.109,7.109,0,0,0,4.9,16.83a9.562,9.562,0,0,0-.894,4.158A9.531,9.531,0,0,0,4.9,25.169a7.14,7.14,0,0,0,2.483,2.9,6.451,6.451,0,0,0,3.638,1.056,6.454,6.454,0,0,0,3.637-1.056,7.154,7.154,0,0,0,2.484-2.9,9.543,9.543,0,0,0,.893-4.181,9.574,9.574,0,0,0-.893-4.158A7.123,7.123,0,0,0,14.659,13.9,6.454,6.454,0,0,0,11.022,12.848Z"
              transform="translate(136)"
              fill="#456975"
            ></path>
            <path
              d="M10.543,24a9.863,9.863,0,0,1-5.38-1.538,10.579,10.579,0,0,1-3.79-4.287A13.681,13.681,0,0,1,0,12,13.691,13.691,0,0,1,1.373,5.824,10.645,10.645,0,0,1,5.141,1.538,9.6,9.6,0,0,1,10.412,0,9.718,9.718,0,0,1,14.7.967a8.953,8.953,0,0,1,3.158,2.461V2.374A2.2,2.2,0,0,1,18.427.835,1.926,1.926,0,0,1,19.909.22a2.034,2.034,0,0,1,1.5.594A2.127,2.127,0,0,1,22,2.374V21.451a2.088,2.088,0,0,1-.588,1.494,2,2,0,0,1-1.5.616,1.963,1.963,0,0,1-1.481-.593,2.119,2.119,0,0,1-.566-1.516V20.308a9.4,9.4,0,0,1-3.092,2.615A8.689,8.689,0,0,1,10.543,24Zm.48-20.132A6.457,6.457,0,0,0,7.385,4.923,7.139,7.139,0,0,0,4.9,7.824,9.509,9.509,0,0,0,4.008,12,9.509,9.509,0,0,0,4.9,16.176a7.128,7.128,0,0,0,2.483,2.9,6.451,6.451,0,0,0,3.638,1.056,6.454,6.454,0,0,0,3.637-1.056,7.142,7.142,0,0,0,2.484-2.9A9.521,9.521,0,0,0,18.036,12a9.417,9.417,0,0,0-.893-4.132,7.313,7.313,0,0,0-2.484-2.922A6.36,6.36,0,0,0,11.022,3.868Z"
              transform="translate(167 9)"
              fill="#456975"
            ></path>
            <path
              d="M.463,45.54A1.514,1.514,0,0,1,0,44.423V1.577A1.489,1.489,0,0,1,.463.428,1.6,1.6,0,0,1,1.587,0H7.8A1.57,1.57,0,0,1,8.958.428a1.55,1.55,0,0,1,.43,1.15V38.114H30.347a1.619,1.619,0,0,1,1.19.46A1.519,1.519,0,0,1,32,39.692v4.731a1.517,1.517,0,0,1-.463,1.117,1.614,1.614,0,0,1-1.19.46H1.587a1.531,1.531,0,0,1-1.124-.46"
              transform="translate(2 43)"
              fill="#456975"
            ></path>
            <path
              d="M.486,45.54A1.477,1.477,0,0,1,0,44.423V1.577A1.453,1.453,0,0,1,.486.428,1.725,1.725,0,0,1,1.667,0H8.334A1.7,1.7,0,0,1,9.549.428,1.511,1.511,0,0,1,10,1.577V44.423a1.536,1.536,0,0,1-.451,1.117A1.624,1.624,0,0,1,8.334,46H1.667a1.65,1.65,0,0,1-1.18-.46"
              transform="translate(43 43)"
              fill="#456975"
            ></path>
            <g transform="translate(61 43)">
              <path
                d="M0,0H40.683V45.93H0Z"
                transform="translate(0.283 0.07)"
                fill="none"
              ></path>
              <g clip-path="url(#a)">
                <path
                  d="M14.131,44.093.066,1.968A1.973,1.973,0,0,1,0,1.378,1.347,1.347,0,0,1,1.38,0H7.23A2.03,2.03,0,0,1,8.61.459a2.492,2.492,0,0,1,.723.984L20.309,35.038,31.35,1.444a1.954,1.954,0,0,1,.657-.984A2.026,2.026,0,0,1,33.388,0H39.3a1.376,1.376,0,0,1,.953.394,1.284,1.284,0,0,1,.427.984l-.132.591-14,42.125a2.517,2.517,0,0,1-2.563,1.837h-7.3a2.451,2.451,0,0,1-2.563-1.837"
                  transform="translate(0.283 0.07)"
                  fill="#456975"
                ></path>
              </g>
            </g>
            <path
              d="M35.613,46H29.534a2.53,2.53,0,0,1-1.652-.493,4.79,4.79,0,0,1-1.057-1.282L18.368,28.652H9.316V44.423a1.523,1.523,0,0,1-.463,1.117A1.621,1.621,0,0,1,7.664,46H1.586a1.537,1.537,0,0,1-1.123-.46A1.52,1.52,0,0,1,0,44.423V1.643A1.669,1.669,0,0,1,.429.46,1.514,1.514,0,0,1,1.586,0H18.7a25.774,25.774,0,0,1,7.243.936,15.535,15.535,0,0,1,5.476,2.809A11.883,11.883,0,0,1,34.861,8.28a15.377,15.377,0,0,1,1.148,6.112,13.6,13.6,0,0,1-2.312,8.05,12.915,12.915,0,0,1-6.475,4.7L36.8,43.9a1.633,1.633,0,0,1,.2.723,1.288,1.288,0,0,1-.429.986A1.392,1.392,0,0,1,35.613,46ZM9.316,7.36v13.8H18.5c2.631,0,4.631-.586,5.946-1.742a6.443,6.443,0,0,0,1.982-5.092,6.657,6.657,0,0,0-1.982-5.159C23.131,7.968,21.13,7.36,18.5,7.36Z"
              transform="translate(110 43)"
              fill="#456975"
            ></path>
            <path
              d="M.464,45.54A1.512,1.512,0,0,1,0,44.423V1.643A1.659,1.659,0,0,1,.431.46,1.512,1.512,0,0,1,1.59,0H30.813a1.625,1.625,0,0,1,1.193.46,1.6,1.6,0,0,1,.464,1.183v4.4a1.487,1.487,0,0,1-.464,1.15,1.69,1.69,0,0,1-1.193.427H9.078v11.5H29.355a1.626,1.626,0,0,1,1.193.46,1.6,1.6,0,0,1,.463,1.183v4.14a1.489,1.489,0,0,1-.463,1.15,1.693,1.693,0,0,1-1.193.427H9.078V38.377H31.343a1.7,1.7,0,0,1,1.193.427A1.492,1.492,0,0,1,33,39.954v4.468a1.489,1.489,0,0,1-.464,1.15A1.691,1.691,0,0,1,31.343,46H1.59a1.536,1.536,0,0,1-1.126-.46"
              transform="translate(157 43)"
              fill="#456975"
            ></path>
            <g transform="translate(0 16)">
              <path
                d="M0,0H15.893V15.893H0Z"
                transform="translate(0 0.084)"
                fill="none"
              ></path>
              <g clip-path="url(#b)">
                <path
                  d="M7.947,15.893A7.946,7.946,0,1,0,0,7.946a7.946,7.946,0,0,0,7.947,7.947"
                  transform="translate(0 0.084)"
                  fill="#68bb45"
                ></path>
              </g>
            </g>
            <path
              d="M8,16A8,8,0,1,0,0,8a8,8,0,0,0,8,8"
              transform="translate(20 16)"
              fill="#edd01a"
            ></path>
            <path
              d="M0,8A8,8,0,1,0,8,0,8,8,0,0,0,0,8"
              transform="translate(40 16)"
              fill="#c82128"
            ></path>
          </svg>

          <i class="ic_modalivre_webapp__arrow-right" v-else></i>
        </transition>
      </a>

      <h1 class="header_titulo mr-auto">{{ titulo }}</h1>

      <button
        type="button"
        class="btn btn-transparent"
        @click="
          () => {
            this.$refs.modalClassificacao.open();
          }
        "
      >
        Legenda
      </button>
      <Classificacao ref="modalClassificacao" />
    </div>

    <HeaderNav />
  </div>
</template>

<script>
import Classificacao from "../modals/Classificacao";

export default {
  components: { Classificacao: Classificacao },

  computed: {
    isHome() {
      return this.$route.path == "/";
    },

    titulo() {
      let _titulo = this.$route.meta.title;

      const _slug = this.$route.params.post;

      if (_slug) {
        const _marca = this.$store.getters.marcas.find(
          _m => _m.post.slug == _slug
        );

        if (_marca) {
          _titulo = _marca.post.titulo;
        } else {
          const _ocorrencia = this.$store.getters.ocorrencias.find(
            _o => _o.slug == _slug
          );

          if (_ocorrencia) {
            _titulo = _ocorrencia.titulo;
          }
        }
      }

      return _titulo ? _titulo : "";
    }
  },

  methods: {
    onClickBtnNav() {
      if (!this.isHome) {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss">
.header {
  background-color: $white;
  border-bottom: 4px solid $gray-400;

  &_navbar {
    padding-top: 28px;
    padding-bottom: 30px;
    flex-wrap: nowrap;
  }

  &_btn_nav {
    $_h: 35px;

    width: 75px;
    height: $_h;
    padding: 0;
    margin-right: 0;

    svg {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    i {
      @include font-size(32px);
      color: $black;
      vertical-align: middle;
      height: 100%;
      display: inline-block;
      line-height: $_h;
    }
  }

  &_titulo {
    @include font-size(26px);
    font-weight: bold;
    margin-bottom: 0;
    color: $modalivre-azul-2;
    padding: 0 20px;
  }
}
</style>
