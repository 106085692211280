//https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
import lodash from "lodash";

export default Vue => {
  const requireComponent = require.context(
    "./components",
    true,
    /[A-Z]\w+\.(vue|js)$/
  );

  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = lodash.upperFirst(
      lodash.camelCase(
        fileName
          .split("/")
          .pop()
          .replace(/\.\w+$/, "")
      )
    );

    // Register component globally
    Vue.component(componentName, componentConfig.default || componentConfig);
  });
};
