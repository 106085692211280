<template>
  <ul class="marcas_list container-lg px-lg-0 px-4">
    <li v-for="item in marcas" :key="item.id">
      <MarcaListItem :marca="item" :secao="secao" />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    marcas: {
      required: true,
      type: Array
    },

    secao: {
      required: false,
      type: Number
    }
  }
};
</script>

<style lang="scss">
.marcas_list {
  list-style-type: none;
}
</style>
