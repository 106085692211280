<template>
  <div class="ranking">
    <RankingHeader v-model="modo" />

    <transition name="fade">
      <secaoPicker v-model="activeSecaoId" v-show="modo == 'ranking'" />
    </transition>
    <transition name="fade">
      <MarcasList
        :marcas="marcasPontos"
        v-show="modo == 'ranking'"
        :secao="activeSecaoId"
      />
    </transition>

    <transition name="fade">
      <MarcasList :marcas="marcasAz" v-show="modo == 'az'" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    modo: "az",
    activeSecaoId: 0
  }),

  computed: {
    ...mapGetters(["marcas", "modelo"]),

    marcasAz() {
      if (!this.marcas.length) {
        return [];
      }

      return [...this.marcas].sort((_item1, _item2) => {
        const __titulo1 = _item1.post.titulo.toUpperCase();
        const __titulo2 = _item2.post.titulo.toUpperCase();

        if (__titulo1 < __titulo2) {
          return -1;
        }
        if (__titulo1 > __titulo2) {
          return 1;
        }

        return 0;
      });
    },

    marcasPontos() {
      if (!this.marcas.length) {
        return [];
      }

      return [...this.marcas].sort((_item1, _item2) => {
        if (!this.activeSecaoId) {
          return (
            _item2.questionario.formulario.pontos_alcancados -
            _item1.questionario.formulario.pontos_alcancados
          );
        }

        const __secaoItem1 = _item1.questionario.secoes.find(
          _s => _s.id == this.activeSecaoId
        );
        const __secaoItem2 = _item2.questionario.secoes.find(
          _s => _s.id == this.activeSecaoId
        );

        if (__secaoItem1 == undefined || __secaoItem2 == undefined) {
          return 0;
        }

        return __secaoItem2.pontos - __secaoItem1.pontos;
      });
    }
  },

  mounted() {}
};
</script>

<style lang="scss">
.ranking {
}
</style>
