<template>
  <a
    v-if="btnTitulo != '' && btnLink"
    :href="btnLink"
    class="btn btn-externo"
    >{{ btnTitulo }}</a
  >
</template>

<script>
export default {
  props: {
    post: {
      required: true,
      type: Object
    }
  },

  computed: {
    btnTitulo() {
      return this.post.hasOwnProperty("acf") && this.post.acf.btn_titulo
        ? this.post.acf.btn_titulo
        : "";
    },

    btnLink() {
      return this.post.hasOwnProperty("acf") && this.post.acf.btn_link
        ? this.post.acf.btn_link
        : "";
    }
  }
};
</script>

<style></style>
