<template>
  <div class="ocorrencias">
    <ViewDescription
      texto="Conheça os casos de trabalho escravo já flagrados no Brasil"
    />

    <OcorrenciasList :ocorrencias="ocorrencias" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["ocorrencias"])
  }
};
</script>

<style></style>
