<template>
  <transition name="fade">
    <div
      class="modal_lgpd modal"
      tabindex="-1"
      role="dialog"
      ref="modal"
      v-if="active"
    >
      <div class="modal-dialog modal-xl mt-3" role="document">
        <div class="modal-content container-lg px-lg-0 px-4">

          <div class="modal-body p-0 text-center">
            <ViewDescription
              texto="Usamos cookies para garantir que oferecemos a melhor experiência em nosso site. Se você continuar a usar este site, assumiremos que está satisfeito com ele."
            />
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close"
            >
              <span aria-hidden="true">Concordo</span>
            </button>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => ({
    active: true
  }),

  methods: {
    open() {
      this.active = true;
    },

    close() {
      this.active = false;
    },

    activeModalLgdp() {
      let _hasModalLgdp = window.sessionStorage.getItem("modal_lgpd");

      if (_hasModalLgdp == null) {
        window.sessionStorage.setItem("modal_lgpd", true);

        _hasModalLgdp = "true";
      }

      return _hasModalLgdp == "true";
    }
  },

  watch: {
    active() {
      window.sessionStorage.setItem("modal_lgpd", this.active);
    }
  },

  beforeMount() {
    this.active = this.activeModalLgdp();
  }
};
</script>

<style lang="scss">
.modal_lgpd {
  &.modal {
    display: block;
    height: auto;
    bottom: 0;
    top: initial;
  }

  background: $modalivre_azul-2;
  color: #fff;
  box-shadow: 0px 2px 4px rgba($black, 0.5);

  .close {
    @include font-size(13px);
    font-weight: bold;
    opacity: 1;
    text-shadow: unset;
    outline: unset !important;
    float: initial;
    color: #fff;
    border: 1px solid #fff;
    padding-left: 18px;
    padding-right: 18px;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;

    span span {
      @include font-size(32px);
      line-height: 32px;
      vertical-align: middle;
      margin-top: -2px;
      display: inline-block;
      font-weight: 500;
    }
  }

  .modal-dialog {
    padding: 0;
    max-width: unset;
    margin: 0.5rem !important;
  }

  .modal-content {
    background: none;
    border: none;
  }

  .modal-header,
  .modal-footer {
    border: none;
    padding: 0;
  }

  .view_description {
    font-weight: 500;
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
  }
}
</style>
