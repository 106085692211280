<template>
  <router-link :to="link" class="marcas_list_item">
    <StatusIcon class="mr-4" :formulario="formulario" />

    <div class="d-block">
      <span class="marcas_list_item-titulo d-block">{{ titulo }}</span>

      <span class="marcas_list_item-pontos pontos d-block">{{ pontos }}</span>
    </div>

    <i class="ic_modalivre_webapp__arrow marcas_list_item-ic_arrow"></i>
  </router-link>
</template>

<script>
export default {
  props: {
    marca: {
      required: true,
      type: Object
    },

    secao: {
      required: false,
      type: Number
    }
  },

  computed: {
    link() {
      return !this.marca ? "" : `/marcas/${this.marca.post.slug}`;
    },

    titulo() {
      return !this.marca ? "" : this.marca.post.titulo;
    },

    pontos() {
      if (this.secao) {
        const secao = this.marca.questionario.secoes.find(
          _s => _s.id == this.secao
        );

        return secao != undefined
          ? `${secao.pontos} pontos em ${secao.titulo}`
          : "";
      }

      const _ponstos = !this.marca ? null : this.formulario.pontos_alcancados;

      return _ponstos != null && _ponstos != undefined
        ? `${_ponstos} pontos gerais`
        : "";
    },

    formulario() {
      return this.marca ? this.marca.questionario.formulario : {};
    }
  }
};
</script>

<style lang="scss">
.marcas_list_item {
  display: flex;
  align-items: center;
  color: $black;
  background-color: $white;
  position: relative;
  justify-items: center;
  border-radius: 8px;
  padding: 14px;
  box-shadow: 0px 6px 16px rgba($black, 0.0979021);
  margin: 7.5px 0;

  &,
  &:hover {
    text-decoration: none;
  }

  > div {
    width: calc(85% - (14px + 14px));
    padding-right: 1rem;
  }

  &-titulo {
    font-weight: 600;
    @include font-size(18px);
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &-pontos {
    @include font-size(14px);
  }

  &-ic_arrow {
    @include font-size(28px);
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
