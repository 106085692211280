<template>
  <div class="modal_classificacao modal fade" ref="modal">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="d-block text-center mb-5">
            <h5 class="modal_classificacao-titulo d-block">Classificação</h5>

            <ViewDescription
              class="d-block"
              texto="Entenda a classificação das marcas"
            />
          </div>

          <Legenda />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    open() {
      window.jQuery(this.$refs.modal).modal({
        // backdrop: false
      });
    }
  }
};
</script>

<style lang="scss">
.modal_classificacao {
  .modal-header {
    border: none;
    padding: 0;
    display: block;

    .close {
      @include font-size(80px);
      opacity: 1;
      padding: 5px 25px;
      font-weight: 300;
      outline: unset !important;
      z-index: 100;
      margin: 0;
    }
  }

  &-titulo {
    @include font-size(34px);
    font-weight: bold;
    color: $modalivre-azul-2;
    margin-bottom: 30px;
  }

  .view_description {
    @include font-size(28px);
  }
}
</style>
