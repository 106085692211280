<template>
  <div class="ocorrencias_list container-lg px-lg-0 px-4">
    <ul>
      <li v-for="ocorrencia in ocorrencias" :key="ocorrencia.id">
        <router-link
          :to="`/ocorrencias/${ocorrencia.slug}`"
          class="ocorrencias_list_item"
        >
          <span class="ocorrencias_list_item-titulo">{{
            ocorrencia.titulo
          }}</span>

          <time class="ocorrencias_list_item-data">{{
            data(ocorrencia.data)
          }}</time>

          <p class="ocorrencias_list_item-descricao">
            {{ textLimit(ocorrencia.resumo) }}
          </p>

          <span class="ocorrencias_list_item-fakelink">
            Leia mais
            <i class="ic_modalivre_webapp__arrow_mini"></i>
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { padLeft } from "../helper";

export default {
  props: {
    ocorrencias: {
      required: true,
      type: Array
    }
  },

  methods: {
    textLimit(string) {
      return String(string).length <= 84
        ? String(string)
        : `${String(string).slice(0, 81)}...`;
    },

    data(data) {
      return `${padLeft(data.getDate(), 2)}/${padLeft(
        data.getMonth() + 1,
        2
      )}/${padLeft(data.getFullYear(), 2)}`;
    }
  }
};
</script>

<style lang="scss">
.ocorrencias_list {
  ul {
    list-style-type: none;
    padding: 0;
    overflow: auto;
    border-radius: 15px;
    box-shadow: 0px 6px 16px rgba($black, 0.0979021);
  }

  li {
    &:nth-child(n + 2) {
      .ocorrencias_list_item {
        border-top: 2px solid $gray-600;
      }
    }
  }

  .ocorrencias_list_item {
    color: initial;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: $white;
    padding: 22px 20px;
    text-decoration: unset !important;

    [class*="ocorrencias_list_item"] {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .ocorrencias_list_item-titulo {
      grid-column-end: 2;
      @include font-size(18px);
      font-weight: 600;
    }

    .ocorrencias_list_item-data {
      grid-column-start: 2;
      text-align: right;
      color: rgba($black, 0.6);
    }

    .ocorrencias_list_item-descricao {
      margin-top: 19px;
      margin-bottom: 10px;
    }

    .ocorrencias_list_item-fakelink {
      font-weight: 600;
      color: $modalivre_azul-2;
    }
  }
}
</style>
