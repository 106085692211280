<template>
  <div class="busca container-lg px-lg-0 px-4" id="busca">
    <!-- Form -->
    <form class="busca_form input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"
          ><i class="ic_modalivre_webapp__busca"></i
        ></span>
      </div>

      <input
        type="text"
        class="form-control"
        placeholder="Nome da marca"
        v-model="term"
      />
    </form>

    <!-- result -->
    <div class="busca_result">
      <!-- Marcas -->
      <transition name="fade">
        <div class="busca_result_box" v-show="term != ''">
          <span class="busca_result_box-result destaque">{{
            numberFoundedMarcas
          }}</span>

          <MarcasList :marcas="filteredMarcas" />
        </div>
      </transition>

      <!-- Ocorrencias -->
      <transition name="fade">
        <div class="busca_result_box" v-show="term != ''">
          <span class="busca_result_box-result destaque">{{
            numberFoundedOcorrencias
          }}</span>

          <OcorrenciasList :ocorrencias="filteredOcorrencias" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const functionFilterLists = (term = "", list = []) => [
  ...list.filter(_i => {
    let __titulo = "";

    if (_i.hasOwnProperty("post")) {
      __titulo = _i.post.titulo;
    } else {
      __titulo = _i.titulo;
    }

    return __titulo.toLowerCase().includes(term.toLowerCase());
  })
];

export default {
  data: () => ({
    term: ""
  }),

  computed: {
    ...mapGetters(["marcas", "ocorrencias"]),

    filteredMarcas() {
      return this.term != "" ? functionFilterLists(this.term, this.marcas) : [];
    },

    filteredOcorrencias() {
      return this.term != ""
        ? functionFilterLists(this.term, this.ocorrencias)
        : [];
    },

    numberFoundedMarcas() {
      return `${this.filteredMarcas.length} ${
        this.filteredMarcas.length == 1
          ? " MARCA ENCONTRADA"
          : " MARCAS ENCONTRADAS"
      }`;
    },

    numberFoundedOcorrencias() {
      return `${this.filteredOcorrencias.length} ${
        this.filteredOcorrencias.length
          ? " OCORRÊNCIA ENCONTRADA"
          : " OCORRÊNCIAS ENCONTRADAS"
      }`;
    }
  }
};
</script>

<style lang="scss">
.busca {
  &_form {
    margin-bottom: 34px;
    height: 77px;
    background-color: $white;
    border-radius: 18px;
    overflow: hidden;

    .input-group-text {
      background: none;
      border: none;
      @include font-size(27px);
      font-weight: 600;
      padding: 0 20px;
    }

    .form-control {
      height: 100%;
      border: none;
      box-shadow: unset !important;
      @include font-size(1rem);
      color: $gray-600;
      padding-left: 0;
    }
  }

  &_result {
    &_box {
      &:nth-child(n + 2) {
        margin-top: 60px;
      }

      &-result {
        display: block;
        padding-bottom: 5px;
        border-bottom: 1px solid $gray-400;
        margin-bottom: 26px;
      }
    }
  }

  .marcas_list,
  .ocorrencias_list {
    max-height: 320px;
    overflow: auto;
  }
}
</style>
