<template>
  <transition name="fade">
    <div
      class="modal_link_apps modal"
      tabindex="-1"
      role="dialog"
      ref="modal"
      v-if="active"
    >
      <div class="modal-dialog modal-xl mt-3" role="document">
        <div class="modal-content container-lg px-lg-0 px-4">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close"
            >
              <span aria-hidden="true">Fechar aviso <span>&times;</span></span>
            </button>
          </div>

          <div class="modal-body p-0">
            <ViewDescription
              texto="Esta é a versão para web do app Moda Livre, desenvolvido pela Repórter Brasil, disponível também para download:."
            />
          </div>

          <div class="modal-footer justify-content-center">
            <!-- <a
              href="https://apps.apple.com/br/app/moda-livre/id768062605"
              class="btn btn-lojas"
              ><img
                src="~@/assets/images/ic_play_store.png"
                width="160"
                height="53"
                alt="Play Store - Moda Livre"
            /></a> -->
            <a
              href="https://apps.apple.com/us/app/moda-livre/id1495246757?l=pt&ls=1"
              class="btn btn-lojas"
              ><img
                src="~@/assets/images/ic_play_store.png"
                width="160"
                height="53"
                alt="Play Store - Moda Livre"
            /></a>

            <a
              href="https://play.google.com/store/apps/details?id=com.modalivre"
              class="btn btn-lojas"
              ><img
                src="~@/assets/images/ic_google_play.png"
                width="160"
                height="53"
                alt="Google Play - Moda Livre"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => ({
    active: true
  }),

  methods: {
    open() {
      this.active = true;
    },

    close() {
      this.active = false;
    },

    activeModal() {
      let _hasModalLinkApps = window.sessionStorage.getItem("modal_link_apps");

      if (_hasModalLinkApps == null) {
        window.sessionStorage.setItem("modal_link_apps", true);

        _hasModalLinkApps = "true";
      }

      return _hasModalLinkApps == "true";
    }
  },

  watch: {
    active() {
      window.sessionStorage.setItem("modal_link_apps", this.active);
    }
  },

  beforeMount() {
    this.active = this.activeModal();
  }
};
</script>

<style lang="scss">
.modal_link_apps {
  &.modal {
    position: relative;
    display: block;
    padding: 0;
    z-index: 100;
  }

  background: $gray-300;
  box-shadow: 0px 2px 4px rgba($black, 0.5);

  .close {
    @include font-size(13px);
    color: $black !important;
    font-weight: bold;
    opacity: 1;
    text-shadow: unset;
    outline: unset !important;

    span span {
      @include font-size(32px);
      line-height: 32px;
      vertical-align: middle;
      margin-top: -2px;
      display: inline-block;
      font-weight: 500;
    }
  }

  .modal-dialog {
    padding: 0;
    max-width: unset;
  }

  .modal-content {
    background: none;
    border: none;
  }

  .modal-header,
  .modal-footer {
    border: none;
    padding: 0;
  }

  .view_description {
    font-weight: 500;
  }
}
</style>
