<template>
  <span class="status_icon" :style="`background-color: ${cor};`">
    <i
      v-if="!questionarioFinalizado"
      class="ic_modalivre_webapp__nao_respondeu"
    ></i>
  </span>
</template>

<script>
export default {
  props: {
    formulario: {
      required: true,
      type: Object
    }
  },

  computed: {
    classificacao() {
      const _configuracoes = this.$store.state.Configuracoes.configuracoes;

      return !_configuracoes.classificacao ||
        !_configuracoes.classificacao.length
        ? []
        : _configuracoes.classificacao;
    },

    pontuacaoMenorMinimo() {
      return this.$store.state.Configuracoes.configuracoes
        .classificacaoPontuacaoMenorMinimo;
    },

    questionarioFinalizado() {
      return this.formulario ? this.formulario.status == "finalizar" : false;
    },

    porcentagemAlcancadaQuestionario() {
      try {
        const _porcentagem = parseFloat(
          (
            (this.formulario.pontos_alcancados * 100) /
            this.formulario.pontos_max
          ).toFixed(1)
        );
        return _porcentagem < 0 ? 0 : _porcentagem;
      } catch (error) {
        return 0;
      }
    },

    cor() {
      try {
        let _item = this.classificacao.find(
          _c =>
            (!this.questionarioFinalizado && _c.pontuacao.sem_pontuacao) ||
            (this.questionarioFinalizado &&
              this.porcentagemAlcancadaQuestionario >=
                parseFloat(_c.pontuacao.minimo) &&
              this.porcentagemAlcancadaQuestionario <=
                parseFloat(_c.pontuacao.maximo))
        );

        if (!_item) {
          _item = this.classificacao.find(
            _c =>
              !_c.pontuacao.sem_pontuacao &&
              parseFloat(_c.pontuacao.minimo) == this.pontuacaoMenorMinimo
          );
        }

        return _item ? _item.cor : "";
      } catch (error) {
        return "";
      }
    }
  }
};
</script>

<style lang="scss">
.status_icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: $white;
  display: inline-table;
  color: $white;
  text-align: center;

  .ic_modalivre_webapp__nao_respondeu {
    vertical-align: middle;
  }
}
</style>
